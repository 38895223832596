import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VideoPlayerEventBase, VideoPlayerEventType } from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import fontFamily from '@@styles/typography/fontFamily';
import type { SupportedClassification } from '@@types/PlaybackStreamData';

const hideAnimationDuration = 1000;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      background: 'radial-gradient(farthest-side at 0px 0px, rgba(11,13,15,0.75) 0%, rgba(11,13,15,0.69) 35%, rgba(11,13,15,0) 100%)',
      position: 'absolute',
      top: 0,
      // Allow player title to disappear before applying the transition
      transitionDelay: '250ms !important',
      transition: `opacity ease-in-out ${hideAnimationDuration}ms`,
      opacity: 0,
      pointerEvents: 'none',
      '&.show': {
        opacity: 1,
        pointerEvents: 'auto',
      },
    },
    container: {
      paddingTop: 40,
      paddingLeft: 40,
      // Allow player title to disappear before applying the transition
      transitionDelay: '250ms !important',
      transition: `clip-path ease-in-out  ${hideAnimationDuration}ms`,
      clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 24,
        paddingLeft: 24,
      },
      '.show &': {
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
      },
    },
    classification: {
      padding: '2px 0px 2px 20px',
      fontSize: '1.5rem',

      fontFamily: fontFamily.secondary,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    classificationG: { borderLeft: '8px solid #15a749' },
    classificationPG: { borderLeft: '8px solid #fff100' },
    classificationM: { borderLeft: '8px solid #00adee' },
    'classificationMA15+': { borderLeft: '8px solid #ed1c24' },
    advice: {
      fontSize: '1rem',
      padding: '4px 0 4px 20px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      },
    },
    adviceG: { borderLeft: '8px solid #15a74950' },
    advicePG: { borderLeft: '8px solid #fff10050' },
    adviceM: { borderLeft: '8px solid #00adee50' },
    'adviceMA15+': { borderLeft: '8px solid #ed1c2450' },
  });
});

const ConsumerAdvice: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, playbackStreamData } = props;

  const classes = useStyles(props);
  const { t } = useTranslation('common');
  const componentRef = useRef<HTMLDivElement>(null);

  const [shouldShow, setShouldshow] = useState<boolean>(false);
  const [stopShowing, setStopShowing] = useState<boolean>(false);
  const [uiShown, setUiShown] = useState<boolean>(true);
  const [isPlayingContent, setIsPlayingContent] = useState<boolean>(false);

  // Used to actually show/hide the overlay based on states
  useEffect(() => {
    let hideTimeoutId: ReturnType<typeof setTimeout> | void;

    const transitionEndHandler = () => {
      setStopShowing(true);
      if (componentRef.current) {
        componentRef.current.removeEventListener('transitionend', transitionEndHandler);
      }
    };

    const cancelHidetimeout = () => {
      if (hideTimeoutId) {
        hideTimeoutId = clearTimeout(hideTimeoutId);
      }
    };

    const startHideTimeout = () => {
      if (!hideTimeoutId) {
        hideTimeoutId = setTimeout(() => {
          cancelHidetimeout();

          if (componentRef.current) {
            setShouldshow(false);
            componentRef.current.addEventListener('transitionend', transitionEndHandler);
          }
        }, 5000);
      }
    };

    if (
      stopShowing === false
      && uiShown === false
      && isPlayingContent === true
    ) {
      setShouldshow(true);
      startHideTimeout();
    } else {
      setShouldshow(false);
      cancelHidetimeout();
    }

    return () => {
      cancelHidetimeout();
    };
  }, [isPlayingContent, stopShowing, uiShown, playbackStreamData]);

  // Logic to update the states based on video events
  useEffect(() => {
    const onUiShown = () => {
      setUiShown(true);
    };

    const onUiHidden = () => {
      setUiShown(false);
    };

    const onAdStarted = () => {
      setIsPlayingContent(false);
    };

    const onAdBreakFinished = () => {
      setIsPlayingContent(true);
    };

    const onPaused = () => {
      setIsPlayingContent(false);
    };

    const onPlaying = (event: VideoPlayerEventBase) => {
      if (event.isAd === false) {
        setIsPlayingContent(true);
      } else {
        setIsPlayingContent(false);
      }
    };

    // when the main content starts playing for the first time, show the consumer advice
    videoPlayer.on(VideoPlayerEventType.AD_STARTED, onAdStarted);
    videoPlayer.on(VideoPlayerEventType.AD_BREAK_FINISHED, onAdBreakFinished);
    videoPlayer.on(VideoPlayerEventType.UI_CONTROLS_SHOW, onUiShown);
    videoPlayer.on(VideoPlayerEventType.UI_CONTROLS_HIDE, onUiHidden);
    videoPlayer.on(VideoPlayerEventType.PAUSED, onPaused);
    videoPlayer.on(VideoPlayerEventType.PLAYING, onPlaying);

    return () => {
      videoPlayer.off(VideoPlayerEventType.AD_STARTED, onAdStarted);
      videoPlayer.off(VideoPlayerEventType.AD_BREAK_FINISHED, onAdBreakFinished);
      videoPlayer.off(VideoPlayerEventType.UI_CONTROLS_SHOW, onUiShown);
      videoPlayer.off(VideoPlayerEventType.UI_CONTROLS_HIDE, onUiHidden);
      videoPlayer.off(VideoPlayerEventType.PAUSED, onPaused);
      videoPlayer.off(VideoPlayerEventType.PLAYING, onPlaying);

      setShouldshow(false);
      setStopShowing(false);
      setIsPlayingContent(false);
    };
  }, [videoPlayer, playbackStreamData]);

  const classification = playbackStreamData.classification || '';
  const consumerAdviceTexts = playbackStreamData.consumerAdviceTexts || [];

  const genAdvice = (classificationCode: SupportedClassification, advice: string, label: string | undefined = undefined) => {
    const text = label || advice;
    return (
      <div
        key={advice}
        className={clsx(classes.advice, classes[`advice${classificationCode}`])}
      >
        {text}
      </div>
    );
  };

  return (
    <Box clone>
      <div ref={componentRef} className={clsx(classes.root, shouldShow && 'show')} data-testid="consumer-advice-card">
        <Box className={clsx(classes.container)}>
          <Box className={clsx(classes.classification, classes[`classification${classification as SupportedClassification}`])}>
            {classification}
          </Box>
          {consumerAdviceTexts?.map((advice: string) => {
            return genAdvice(classification as SupportedClassification, advice);
          })}
          {(consumerAdviceTexts?.length === 0 && classification) && genAdvice(classification as SupportedClassification, t(`classification.${classification}`))}
        </Box>
      </div>
    </Box>
  );
};

export default ConsumerAdvice;
