import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { find, get } from 'lodash';
import { FunctionComponent, useEffect, useState, useCallback } from 'react';

import GoBackButton from '@@src/components/Buttons/GoBackButton';
import SbsControlBar from '@@src/lib/VideoPlayerV2/BitmovinPlayerUI/components/SbsControlBar';
import {
  VideoPlayerEventCallback,
  VideoPlayerEventType,
  VideoPlayerUiVariantResolveEvent,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import blue from '@@styles/colors/blue';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      zIndex: 9999,
      width: '100%',
      pointerEvents: 'none',
      height: 179,
      top: 0,
      background: 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 47%, rgba(0,0,0,0.04) 80%, rgba(0,0,0,0) 100%)',
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out',
      '&.show': {
        opacity: 1,
      },
      [theme.breakpoints.down('xs')]: {
        height: 140,
      },
    },
    arrowBackButton: {
      pointerEvents: 'auto',
      position: 'absolute',
      padding: 0,
      top: 61,
      left: 38,
      borderRadius: 0,
      opacity: 0.8,
      transition: 'left 0.3s ease-in-out, top 0.3s ease-in-out',
      '&:focus-visible': {
        outline: `3px solid ${blue.navy}`,
        borderRadius: 4,
      },
      '.show &:hover': {
        background: 'transparent',
        opacity: 1,
      },
      zIndex: 9999,
      [theme.breakpoints.down('sm')]: {
        top: 18,
        left: 16,
      },
    },
    arrowBackIcon: {
      width: 42,
      height: 42,
      transition: 'left 0.3s ease-in-out, top 0.3s ease-in-out',
      [theme.breakpoints.up('md')]: {
        width: 48,
        height: 48,
      },
    },
    videoInfo: {
      position: 'absolute',
      display: 'flex',
      zIndex: 1,
      top: 53,
      left: 110,
      height: 63,
      flexDirection: 'column',
      justifyContent: 'center',
      transition: 'left 0.3s ease-in-out, top 0.3s ease-in-out',
      [theme.breakpoints.down('sm')]: {
        top: 8,
        left: 78,
      },
      '.pip:not(.isSafari):not(.isMobile):not(.isTablet) &': {
        top: 120,
        left: 98,
      },
    },
    videoInfoTitle: {
      fontSize: '1.5rem',
      fontFamily: 'Ubuntu',
      fontWeight: 'bold',
      marginBottom: 2,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125em',
      },
    },
    videoInfoSubtitle: {
      fontSize: '1.125em',
      fontFamily: 'Ubuntu',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        marginRight: 5,
      },
    },
  });
});

const ContentMetadataBar: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, video, playbackStreamData } = props;
  const classes = useStyles(props);
  const [show, setShow] = useState<boolean>(false);
  const [playerCloseUrl, setPlayerCloseUrl] = useState<string | undefined >(undefined);

  useEffect(() => {
    const onControlsShow = () => {
      setShow(true);
    };

    const onControlsHide = () => {
      setShow(false);
    };

    const onUiLoaded = (event: VideoPlayerUiVariantResolveEvent) => {
      const uiComponents = event.ui.getUI().getComponents();
      const controlBar = find(uiComponents, (component) => {
        return component instanceof SbsControlBar;
      });

      (controlBar as SbsControlBar).onShow.subscribeOnce(onControlsShow);
    };

    const getPlayerCloseUrl = () => {
      const sourceConfig = videoPlayer.getSource();
      setPlayerCloseUrl(sourceConfig?.metadata?.backUrl);
    };

    getPlayerCloseUrl();

    videoPlayer.on(VideoPlayerEventType.UI_CONTROLS_SHOW, onControlsShow);
    videoPlayer.on(VideoPlayerEventType.UI_CONTROLS_HIDE, onControlsHide);
    videoPlayer.on(VideoPlayerEventType.UI_LOADED, onUiLoaded as VideoPlayerEventCallback);
    videoPlayer.on(VideoPlayerEventType.SOURCE_LOADED, getPlayerCloseUrl as VideoPlayerEventCallback);

    window.onbeforeunload = () => {
      videoPlayer?.pause('pageunload');
    };

    return () => {
      window.onbeforeunload = null;
      videoPlayer.off(VideoPlayerEventType.SOURCE_LOADED, getPlayerCloseUrl as VideoPlayerEventCallback);
      videoPlayer.off(VideoPlayerEventType.UI_LOADED, onUiLoaded as VideoPlayerEventCallback);
      videoPlayer.off(VideoPlayerEventType.UI_CONTROLS_SHOW, onControlsShow);
      videoPlayer.off(VideoPlayerEventType.UI_CONTROLS_HIDE, onControlsHide);
    };
  }, [videoPlayer]);

  const handleCloseClick = useCallback(() => {
    videoPlayer.pause('playerclose');
  }, [videoPlayer]);

  const title = video?.playerTitles?.title || playbackStreamData.title;

  return (
    <div className={clsx(classes.root, { show })}>
      <GoBackButton backUrl={playerCloseUrl} onClick={handleCloseClick}/>
      <div className={classes.videoInfo}>
        <div className={classes.videoInfoTitle}>{title}</div>
        <div className={classes.videoInfoSubtitle}>{video?.playerTitles?.subtitle}</div>
      </div>
    </div>
  );
};

export default ContentMetadataBar;
