import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import { Duration } from 'luxon';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PlayIcon from '@@icons/Play';
import OdFavouriteButton from '@@src/components/Buttons/OdFavouriteButton';
import { getImageUrl } from '@@src/components/Html/Image';
import Button from '@@src/components/Inputs/Button';
import Metadata from '@@src/components/Tiles/Metadata';
import { durationShortFormatParams } from '@@src/i18n';
import { generatePathFromLinkProps } from '@@src/routes';
import fontFamily from '@@styles/typography/fontFamily';
import OnDemand from '@@types/OnDemand';

import EndCard from '../EndCard';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    metadata: {
      marginTop: '1em',
      fontFamily: fontFamily.secondary,
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: '1.125em',
      fontWeight: 500,
      alignItems: 'center',
      rowGap: 5,
      '& > span': {
        marginRight: 15,
      },
      lineHeight: 1,
      '@media only screen and (max-height: 400px)': {
        marginTop: '0.5em',
      },
    },
    metadataBadges: {
      display: 'flex',
      fontSize: '1.5rem',
      lineHeight: 1.2,
      '& span': {
        marginRight: 2,
      },
      marginRight: 13,
      '& svg': {
        height: '0.8em',
      },
      '& > *:not(:last-child)': {
        marginRight: 8,
      },
    },
    favouriteButton: {
      fontSize: 48,
      [theme.breakpoints.down('md')]: {
        fontSize: 42,
      },
    },
    buttonContainer: {
      marginTop: '1.5em',
      '@media only screen and (max-height: 400px)': {
        marginTop: '1em',
      },
    },
    endCardContainer: {
      [theme.breakpoints.up('md')]: {
        width: '40%',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 600,
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 490,
      },
    },
  });
});

export interface RecommendationCardProps {
  /** The video that is recommended. */
  video: OnDemand.Video;

  /** The function that will be called when the play button is clicked */
  onClickPlay?(): void;

  /** The function that will be called when the more info is clicked */
  onClickMoreInfo?(): void;

  /** The function that will be called when the content has added to favourites */
  onFavourited?(): void;

  /** The function that will be called when the content has removed to favourites */
  onUnfavourited?(): void;
}

const RecommendationCard: FunctionComponent<RecommendationCardProps> = (props) => {
  const {
    video,
    onClickMoreInfo,
    onClickPlay,
    onFavourited,
    onUnfavourited,
  } = props;
  const classes = useStyles(props);

  const { t } = useTranslation('common');
  const theme = useTheme();
  const mediaMatchesMd = useMediaQuery(theme.breakpoints.down('md'));

  const {
    type,
    duration: contentDuration,
    publicationYear,
    classification,
    hasClosedCaption,
    isHighDefinition,
    shortDescription,
    description,
  } = video;

  let title;
  let imageUrl;
  let seasonNumber = null;
  let episodeNumber = null;
  let numberOfSeasonsAvailable = null;
  let playButtonText;

  if (video.type === 'Episode') {
    title = get(video, 'episodeData.programName');
    imageUrl = 'odImageId' in video ? getImageUrl(video.odImageId) : get(video, 'images.default');
    seasonNumber = video.episodeData?.seasonNumber;
    episodeNumber = video.episodeData?.episodeNumber;
    numberOfSeasonsAvailable = video.numberOfSeasons;
    playButtonText = t('videoPlayer.playEpisode', {
      seasonNumber,
      episodeNumber,
    });
  } else {
    title = get(video, 'title');
    imageUrl = 'odImageId' in video ? getImageUrl(video.odImageId) : get(video, 'images.hd720');
    playButtonText = t('videoPlayer.playVideo');
  }

  const country = video.countries ? video.countries[0] : null;
  const genre = video.genres ? video.genres[0] : null;
  const duration = Duration.fromObject({ hours: 0, minutes: 0, seconds: contentDuration + 30 })
    .normalize()
    .set({ seconds: 0 });

  const handleClickMoreInfo = useCallback(() => {
    if (onClickMoreInfo) {
      onClickMoreInfo();
    }
  }, [onClickMoreInfo]);

  const handleClickPlay = useCallback(() => {
    if (onClickPlay) {
      onClickPlay();
    }

    // Sending this event in order for the WatchPageV2 to not reset the videoIds history and allow back button to properly
    // work after clicking on a recommended video
    const event = new CustomEvent('OdPlayerVideoChange', { detail: { videoId: video.id } });
    document.dispatchEvent(event);
  }, [onClickPlay, video.id]);

  let moreInfoRoute = get(video, 'route');
  if (video.type === 'Episode') {
    moreInfoRoute = get(video, 'episodeData.seriesRoute');
  }

  const metadata = (
    <div className={classes.metadata}>
      {genre && <span>{genre}</span>}
      {type !== 'Episode' && (
        <span>
          <time
            className={classes.duration}
            dateTime={duration.toISO()}
            aria-label={t('video.duration', { duration: contentDuration })}
          >
            <span>
              {t('video.duration', {
                duration: contentDuration,
                formatParams: {
                  duration: durationShortFormatParams,
                },
              })}
            </span>
          </time>
        </span>
      )}
      {type === 'Episode' && numberOfSeasonsAvailable && numberOfSeasonsAvailable > 0 && (
        <span>{t('metadata.seasonsAvailable', { count: numberOfSeasonsAvailable })}</span>
      )}
      {publicationYear !== null && <span>{publicationYear}</span>}
      {country && <span>{country}</span>}
      {(classification || isHighDefinition || hasClosedCaption) && (
        <Metadata
          isActive
          isHd={isHighDefinition}
          hasCc={hasClosedCaption}
          classification={classification || undefined}
          classes={{ root: classes.metadataBadges }}
        />
      )}
    </div>
  );

  const onFavouriteButtonClick = useCallback((e: MouseEvent, favourited: boolean, _item: Partial<OnDemand.Video | OnDemand.TvSeries>) => {
    if (favourited && onFavourited) {
      onFavourited();
    } else if (!favourited && onUnfavourited) {
      onUnfavourited();
    }
  }, [onFavourited, onUnfavourited]);

  const buttons = (
    <Box className={classes.buttonContainer}>
      <Grid container spacing={1}>
        <Grid item>
          <Button
            size={mediaMatchesMd ? 'medium' : 'large'}
            onClick={handleClickPlay}
            startIcon={<PlayIcon/>}
          >
            {playButtonText}
          </Button>
        </Grid>
        <Grid item>
          <Button
            buttonType="secondary"
            size={mediaMatchesMd ? 'medium' : 'large'}
            onClick={handleClickMoreInfo}
            to={generatePathFromLinkProps(moreInfoRoute)}
          >
            {t('videoPlayer.moreInfo')}
          </Button>
        </Grid>
        <Grid item>
          <OdFavouriteButton
            item={video}
            classes={{ root: classes.favouriteButton }}
            onClick={onFavouriteButtonClick}
          />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <EndCard
      backgroundImageUrl={imageUrl}
      cardTitle={t('videoPlayer.tryThisNext')}
      videoTitle={title}
      description={shortDescription || description}
      metadata={metadata}
      buttons={buttons}
      classes={{
        container: classes.endCardContainer,
      }}
    />
  );
};

export default RecommendationCard;
