import { CircularProgress, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Player } from 'bitmovin-player';
import clsx from 'clsx';
import * as ls from 'local-storage';
import { get, has, uniq } from 'lodash';
import { FunctionComponent, useEffect, useState, useCallback, useRef, Fragment } from 'react';
import { isDesktop, isMobile, isMobileOnly, isSafari, isTablet } from 'react-device-detect';
import { ErrorBoundary } from 'react-error-boundary';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import uniqid from 'uniqid';

import '@sbs/bitmovin-player-ui/dist/css/bitmovinplayer-ui.css';
import { useAppDispatch, useAppSelector, useAppStore } from '@@src/hooks/store';
import PlayerEvents from '@@src/lib/VideoPlayer/PlayerEvents';
import { updatePlayerControlsVisibilityClass, dispatchSetVideoProgress } from '@@src/utils/helpers';
import { getProgressStoreReady, getProgress, setVideoProgress } from '@@stores/ProgressStore';
import { getServerSideRendered, getSetting } from '@@stores/SettingsStore';
import { getIsLoggedIn, getIsReady, getSessionId, getUserId } from '@@stores/UserStore';
import blue from '@@styles/colors/blue';
import VideoStream from '@@types/VideoStream';
import { BITMOVIN_LICENSE_KEY, BUILD_HASH, PROGRESS_RECORD_PATH, V3_API_HOST } from '@@utils/constants';
import Logger from '@@utils/logger/Logger';
import '@@src/lib/VideoPlayer/VideoPlayer.css';
import '@@src/lib/VideoPlayer/Vendor/conviva-core-sdk.min';
import '@@src/lib/VideoPlayer/Vendor/OzTAMService.min';

import OnDemand from '../@types/OnDemand';
import { NotFoundError, VideoLoadingError } from '../components/Error/Error';
import Button from '../components/Inputs/Button';
import { dispatchOpenSignIn } from '../components/Login/LoginForm';
import AdOnPausePluginV2 from '../components/Video/CustomPlugins/AdOnPause/AdOnPausePluginV2';
import AutoplayNextEpisodePluginV2 from '../components/Video/CustomPlugins/AutoplayNextEpisode/AutoplayNextEpisodePluginV2';
import VideoConsumerAdvicePluginV2 from '../components/Video/CustomPlugins/ConsumerAdvice/ConsumerAdvicePluginV2';
import DataLayerTrackingPluginV2 from '../components/Video/CustomPlugins/DataLayerTracking/DataLayerTrackingPluginV2';
import EpisodePickerPluginV2 from '../components/Video/CustomPlugins/EpisodePicker/EpisodePickerPluginV2';
import NextEpisodeEndCardPluginV2 from '../components/Video/CustomPlugins/NextEpisodeEndCard/NextEpisodeEndCardPluginV2';
import RecommendationEndCardPluginV2 from '../components/Video/CustomPlugins/RecommendationEndCard/RecommendationEndCardPluginV2';
import SkipIntroPluginV2 from '../components/Video/CustomPlugins/SkipForward/SkipIntroPluginV2';
import SkipRecapPluginV2 from '../components/Video/CustomPlugins/SkipForward/SkipRecapPluginV2';
import VideoPlayerPluginV2 from '../components/Video/CustomPlugins/VideoPlayerPluginV2';
import useQuery from '../hooks/useQuery';
import { ReactComponent as ArrowBackIcon } from '../images/icons/arrow-back.svg';
import BitmovinClient from '../lib/VideoPlayer/BitmovinClient';
import { generateFullPathFromLinkProps, generatePathFromLinkProps } from '../routes';
import { getVideo } from '../services/VideoService';
import fontFamily from '../styles/typography/fontFamily';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    root: {
      position: 'fixed',
      zIndex: 1300,
      right: 0,
      bottom: 0,
      top: 0,
      left: 0,
      color: 'white',
      backgroundColor: 'black',
      '&.debug::before': {
        content: '"v1"',
        position: 'absolute',
        zIndex: 9,
        top: 10,
        right: 10,
        textShadow: '0px 0px 10px #ffffff',
      },
    },
    playerContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
      fontSize: 16,
      '& video::-webkit-media-text-track-container': {
        overflow: 'visible !important',
        '-webkit-transform': 'translateY(-20%) !important',
        transform: 'translateY(-20%) !important',
      },
      '& .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .bmpui-ui-selectbox': {
        color: theme.palette.secondary.main,
      },
      '& .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker': {
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
      },
      '& .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .bmpui-ui-settings-panel, .bmpui-ui-settings-panel .bmpui-label': {
        fontFamily: fontFamily.secondary,
      },
      '& .bmpui-ui-subtitle-label': {
        fontSize: 'calc(18px + 1vw) !important',
        fontFamily: `${fontFamily.roboto} !important`,
      },
      '& .bmpui-ui-uicontainer': {
        fontFamily: fontFamily.primary,
      },
      '&.casting': {
        backgroundColor: theme.palette.background.default,
      },
      '& .bitmovinplayer-ima-container': {
        pointerEvents: 'none',
      },
    },
    backgroundImage: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
    },
    adCta: {
      position: 'absolute',
      left: 39,
      zIndex: 9999,
      display: 'none',
      alignItems: 'center',
      bottom: 120,
      '.skip-ad-controls &': {
        bottom: 175,
      },
      [theme.breakpoints.down('sm')]: {
        bottom: 75,
        '.skip-ad-controls &': {
          bottom: 120,
        },
      },
    },
    adMessageTitle: {
      fontWeight: 'bold',
      textShadow: '2px 2px 4px black',
    },
    adMessage: {
      marginRight: 12,
      textShadow: '2px 2px 4px black',
      fontSize: '0.875em',
    },
    adButton: {
      marginLeft: 30,
      paddingTop: 3,
      paddingBottom: 3,
      fontSize: '0.75rem',
      opacity: 0.7,
      '&:focus-visible': {
        outline: `3px solid ${blue.navy}`,
      },
    },
    arrowBackButton: {
      pointerEvents: 'auto',
      position: 'absolute',
      padding: 0,
      top: 61,
      left: 38,
      borderRadius: 0,
      opacity: 0.8,
      transition: 'left 0.3s ease-in-out, top 0.3s ease-in-out',
      '&:focus-visible': {
        outline: `3px solid ${blue.navy}`,
        borderRadius: 4,
      },
      '.player-controls-visible &:hover': {
        background: 'transparent',
        opacity: 1,
      },
      zIndex: 9999,
      [theme.breakpoints.down('sm')]: {
        top: 18,
        left: 16,
      },
    },
    arrowBackIcon: {
      width: 48,
      height: 48,
      transition: 'left 0.3s ease-in-out, top 0.3s ease-in-out',
    },
    errorMessage: {
      maxWidth: 640,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '& p': {
        whiteSpace: 'pre-line',
      },
    },
    videoInfoContainer: {
      zIndex: 3,
      width: '100%',
      pointerEvents: 'none',
      height: 179,
      position: 'absolute',
      top: 0,
      background: 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 47%, rgba(0,0,0,0.04) 80%, rgba(0,0,0,0) 100%)',
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out',
      transitionDelay: '0.4s',
      '.player-controls-visible &, .casting &': {
        opacity: 1,
      },
      [theme.breakpoints.down('xs')]: {
        height: 140,
      },
    },
    videoInfo: {
      position: 'absolute',
      display: 'flex',
      zIndex: 1,
      top: 53,
      left: 110,
      height: 63,
      flexDirection: 'column',
      justifyContent: 'center',
      transition: 'left 0.3s ease-in-out, top 0.3s ease-in-out',
      [theme.breakpoints.down('sm')]: {
        top: 8,
        left: 78,
      },
      '.casting &': {
        display: 'none',
      },
      '.pip:not(.isSafari):not(.isMobile):not(.isTablet) &': {
        top: 120,
        left: 98,
      },
    },
    castVideoInfo: {
      display: 'none',
      '.casting &': {
        display: 'block',
      },
    },
    videoInfoTitle: {
      fontSize: '1.5em',
      fontFamily: 'Ubuntu',
      fontWeight: 'bold',
      marginBottom: 2,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125em',
      },
    },
    videoInfoSubtitle: {
      fontSize: '1.125em',
      fontFamily: 'Ubuntu',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        marginRight: 5,
      },
    },
    videoElement: {
      position: 'absolute',
      transition: 'all .3s ease-out',

      '&.endcardFloat': {
        position: 'absolute',
        width: 'calc(100vw * 0.2898)',
        bottom: 'calc(33% - 4vw - 3vh)',
        height: 'calc(100vw * 0.2898 * 0.5625)',
        right: 'calc(100vw * 0.1234)',
        left: 'auto',
        top: 'auto',
        zIndex: 9999,
        transform: 'translate3d(0, 0, 0)',
        border: '2px solid rgba(0, 0, 0, 0)',
        '&:hover': {
          border: '2px solid white',
        },
      },
      '&.endcardFloatEnd': {
        position: 'absolute',
        right: 0,
        bottom: 0,
        left: 'auto',
        top: 'auto',
        zIndex: 9999,
        transform: 'translate3d(0, 0, 0)',
      },
    },
    loadingContainer: {
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      top: 'calc(50% - 30px)',
      zIndex: 9999,

      '.source-loaded &, .has-error &': {
        display: 'none',
      },

      '.casting-init &, .buffering &': {
        display: 'block',
      },
    },
  });
});

export interface PlayerMetadata {
  name: string;
  sdk: string;
  placement: string;
}

export class VideoPlayerApi {
  public id: string;
  protected bitmovinClient: BitmovinClient;
  protected playerMetadata: PlayerMetadata;

  public constructor(
    id: string,
    bitmovinClient: BitmovinClient,
    playerMetadata: PlayerMetadata,
  ) {
    this.id = id;
    this.bitmovinClient = bitmovinClient;
    this.playerMetadata = playerMetadata;
  }

  public getBitmovinClient(): BitmovinClient {
    return this.bitmovinClient;
  }

  public getPlayerEvents(): PlayerEvents {
    return this.bitmovinClient.playerEvents;
  }

  public getVideo(): OnDemand.Video {
    return this.bitmovinClient.getVideoMetadata();
  }

  public getContainer(): HTMLElement {
    return document.getElementById(`video-player-${this.id}`);
  }

  public getNextVideo(): OnDemand.Episode | null {
    return get(this.bitmovinClient.getVideoStreamMetadata(), 'nextVideo');
  }

  public getRecommendationEndpoint(): string | null {
    const videoStreamMetadata = this.bitmovinClient.getVideoStreamMetadata();
    if (videoStreamMetadata) {
      return this.bitmovinClient.getVideoStreamMetadata().recommendationApi;
    }

    return null;
  }

  public getTrackingTime(name: VideoStream.TrackingEventKey): number {
    return get(this.bitmovinClient.getVideoStreamMetadata(), `trackingEvents.${name}`);
  }

  getPlayerMetadata(): PlayerMetadata {
    return this.playerMetadata;
  }
}

interface WatchPageProps {
  onLoadSuccess?: (title: string) => void,
  onLoadError?: (errorCode: string, errorMessage: string) => void,
}

const WatchPage: FunctionComponent<WatchPageProps> = (props) => {
  const {
    onLoadSuccess = () => {},
    onLoadError = () => {},
  } = props;
  const userId = useAppSelector(getUserId);
  const sessionId = useAppSelector(getSessionId);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const serverSideRendered = useAppSelector(getServerSideRendered);
  const history = useHistory();
  const [videoNotFound, setVideoNotFound] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [video, setVideo] = useState(undefined);
  const [videoStreamData, setVideoStreamData] = useState(undefined);
  const { id: requestedVideoId } = useParams<{ id: string }>();
  const { t, i18n: { language } } = useTranslation('common');
  const adBlockerDetected = useAppSelector((state) => {
    return getSetting(state, 'adBlockDetected');
  });

  const loginStatusIsReady = useAppSelector(getIsReady);
  const isProgressReady = useAppSelector(getProgressStoreReady);

  const areUserApisReady = loginStatusIsReady && (isProgressReady || !isLoggedIn);

  // Only show the background image when there is no videoStreamData as this means the user is logged out
  // and the video requires login
  const showBackgroundImage = !videoStreamData;

  const classes = useStyles(props);
  const defaultErrorOptions = useRef({
    title: t('videoPlayer.videoPlaybackErrorTitle'),
    body: t('videoPlayer.videoPlaybackErrorBody'),
  });

  const store = useAppStore();

  const queryString = useQuery();
  const canResume = get(queryString, 'noresume', '0') !== '1';
  const useConvivaTouchstone = get(queryString, 'touchstone', null) !== null;

  let demuxed = true;
  if (process.env.BVAR_PLAYBACK_DEMUXED) {
    demuxed = process.env.BVAR_PLAYBACK_DEMUXED === 'true';
  }
  if (ls.get('demuxed') !== null) {
    demuxed = ls.get('demuxed');
  }

  const [videoIds, setVideoIds] = useState([]);
  const [waitForDai, setWaitForDai] = useState(true);
  const [playerId, setPlayerId] = useState<string>(null);
  const [bitmovinClient, setBitmovinClient] = useState<BitmovinClient>(null);
  const [playbackErrorPayload, setPlaybackErrorPayload] = useState(null);

  const shouldAutoplay = !isMobile && !isSafari;

  const [pluginsV2, setPluginsV2] = useState([]);

  const dispatch = useAppDispatch();

  const playerMetadata = useRef<PlayerMetadata>({
    name: `ondemand:web:web ${isMobile ? 'mobile' : 'desktop'}:main:v4`,
    sdk: `bitmovin:${Player.version}`,
    placement: 'main',
  }).current;

  const handleVideoError = useCallback((options = undefined) => {
    if (options) {
      const { title: optionTitle, body: optionBody } = options;
      setPlaybackErrorPayload({ title: optionTitle, body: optionBody });
    } else {
      setPlaybackErrorPayload(defaultErrorOptions.current);
    }
  }, []);

  const errorBoundaryFallbackRender = useCallback(() => {
    return null;
  }, []);

  const updateBitmovinClientMetadata = useCallback((videoId: string) => {
    bitmovinClient.init(
      {
        licenseKey: BITMOVIN_LICENSE_KEY,
        id: playerId,
        containerId: `video-player-${playerId}`,
        videoElementId: `video-content-${playerId}`,
        adCtaElementId: `adcta-${playerId}`,
        videoId,
        withAdSnapback: true,

        playerMetadata,

        onControlsToggle: (shown = true) => {
          updatePlayerControlsVisibilityClass(shown, playerId);
        },
        recordProgress: (id, position, duration: number | null) => {
          if (isLoggedIn && duration > 600) {
            dispatchSetVideoProgress(duration, position, id, dispatch, setVideoProgress);
          }
        },
        playerEventsOptions: {
          progressFrequency: 90,
          milestones: [25, 50, 75, 95],
          duration: null,
        },
        plugins: {
          // can be disabled for testing, otherwise true
          ...(process.env.ENABLE_CONVIVA === 'true' || typeof process.env.ENABLE_CONVIVA === 'undefined') && {
            conviva: {
              useTouchstone: useConvivaTouchstone,
              environment: process.env.BVAR_CONVIVA_ENV,
            },
          },
          // can be disabled for testing, otherwise true
          ...(process.env.ENABLE_OZTAM === 'true' || typeof process.env.ENABLE_OZTAM === 'undefined') && {
            oztam: {
              userId,
              environment: process.env.BVAR_OZTAM_ENV,
              vendorVersion: `SBSOnDemand_Web_2.0-${BUILD_HASH}`,
            },
          },
        },
        castMetadata: {
          customData: {
            janrainId: userId,
            lastViewedRequest: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              pass: 'odwebsite',
              url: `${V3_API_HOST}${PROGRESS_RECORD_PATH}?seconds=[SWITCH_STOP_TIME_SECS]&videoid=${videoId}&context=odwebsite`,
              user: sessionId,
            },
            userPreferences: {
              autoplayNextEpisode: true,
            },
            senderPlatform: 'web',
            sbsSession: sessionId,
          },
        },
        debugMode: process.env.BVAR_PLAYER_DEBUG === 'true',
        errorHandler: handleVideoError,
        userId,
      },
    );
  }, [
    bitmovinClient, playerId, playerMetadata, useConvivaTouchstone, userId, sessionId, handleVideoError,
    isLoggedIn, dispatch,
  ]);

  const handleVideoPlayerClose = useCallback((videoData: OnDemand.Video) => {
    if (videoData && (serverSideRendered || history.length === 1 || videoIds.length > 1)) {
      // go to details page if the page was server side rendered, there is no history, or we have been playing multiple videos
      let path = generatePathFromLinkProps(videoData.route);

      // if the video route is the watch page (Eg: clips don't have a details page), go to home page
      if (videoData.route.name === 'watch') {
        path = '/';
      } else if (videoData.type === 'Episode') {
        // if video is an episode, go to the series details page
        path = generatePathFromLinkProps(videoData.episodeData.seriesRoute);
      }

      history.replace(path);
    } else {
      // if we have history, go back - same behaviour as pressing browser back button
      history.goBack();
    }
  }, [history, serverSideRendered, videoIds.length]);

  const handleArrowBackClick = useCallback(() => {
    handleVideoPlayerClose(video);
  }, [handleVideoPlayerClose, video]);

  const getResumePosition = useCallback((_videoStreamData, videoId: string) => {
    let resumePosition = 0;
    const progressData = getProgress(store.getState(), videoId);
    const creditTime = get(_videoStreamData, 'trackingEvents.credits_begin');

    const completed = get(progressData, 'completed', false);
    const percent = get(progressData, 'percent', 0);
    const seconds = get(progressData, 'seconds', 0);

    if (
      completed === false
      && percent !== 100
      && (!creditTime || seconds < creditTime)
    ) {
      resumePosition = seconds;
    } else {
      Logger.info('Content was previously completed, starting from the beginning');
    }

    return resumePosition;
  }, [store]);

  useEffect(() => {
    if (videoStreamData) {
      onLoadSuccess(videoStreamData.video.playerTitles.title);
    }
  }, [videoStreamData, onLoadSuccess]);

  // Create bitmovin instance
  useEffect(() => {
    if (adBlockerDetected) {
      handleVideoError();
    } else if (!bitmovinClient) {
      setBitmovinClient(new BitmovinClient());
      setPlayerId(uniqid());
    }

    return () => {
      if (adBlockerDetected !== undefined && bitmovinClient) {
        bitmovinClient.unload(() => {
          setBitmovinClient(undefined);
        });
      }
    };
  }, [bitmovinClient, adBlockerDetected, handleVideoError]);

  const handleVideoNotFoundError = useCallback(() => {
    if (!video || video.id !== requestedVideoId) {
      // get video object to check if it's a coming soon
      getVideo(requestedVideoId)
        .then((data) => {
          const expired = get(data, 'expired');
          const available = get(data, 'available');

          if (!expired && !available) {
            // If coming soon, we will always redirect to the video details page even for episodes.
            const pageRoute = get(data, 'route', null);
            history.replace(generatePathFromLinkProps(pageRoute));
          } else {
            setVideoNotFound(true);
          }
        })
        .catch(() => {
          setVideoNotFound(true);
        });
    }
  }, [video, requestedVideoId, history]);

  const handleUnauthorizedError = useCallback(() => {
    if (!video || video.id !== requestedVideoId) {
      // get video to render the background image if sign in is required
      getVideo(requestedVideoId)
        .then((data) => {
          setVideo(data);
        })
        .catch(() => {
          setVideoNotFound(true);
        });
    }
  }, [requestedVideoId, video]);

  const processVideoMetadataError = useCallback((error) => {
    switch (error.message) {
      case 'Video Expired':
        // if video stream returns expired, we will render not found
        setVideoNotFound(true);
        onLoadError('404', 'Page Not Found');
        break;

      case 'Video Not Found':
        handleVideoNotFoundError();
        onLoadError('404', 'Page Not Found');
        break;

      case 'Unauthorized':
        handleUnauthorizedError();
        Logger.info('This video requires sign-in, opening sign-in form');
        dispatchOpenSignIn();
        onLoadError('401', 'Unauthorized');
        break;

      case 'Missing video ID':
        Logger.warn('Missing video ID');
        onLoadError('400', 'Missing video ID');
        break;

      default:
        // other error messages from video stream would result in video loading error
        Logger.info(`There was an error loading video ${requestedVideoId}`, { errorMessage: error.message });
        setHasError(true);
        onLoadError('500', error.message);
        break;
    }
  }, [onLoadError, handleVideoNotFoundError, handleUnauthorizedError, requestedVideoId]);

  // Fetch video metadata
  useEffect(() => {
    if (!bitmovinClient || !areUserApisReady) {
      return null;
    }

    const customPluginsV2: (new (videoPlayerApi: VideoPlayerApi) => VideoPlayerPluginV2)[] = [
      VideoConsumerAdvicePluginV2,
      AutoplayNextEpisodePluginV2,
      NextEpisodeEndCardPluginV2,
      RecommendationEndCardPluginV2,
      SkipIntroPluginV2,
      SkipRecapPluginV2,
      DataLayerTrackingPluginV2,
      AdOnPausePluginV2,
      EpisodePickerPluginV2,
    ];

    const instantiatePluginsV2 = () => {
      const videoPlayerApi = new VideoPlayerApi(playerId, bitmovinClient, playerMetadata);

      return customPluginsV2
        .map((PluginClass) => {
          // instantiate the plugin class
          try {
            return new PluginClass(videoPlayerApi);
          } catch (error) {
            Logger.error('Video plugin error', {
              error: {
                stack: error.stack,
                message: error.message,
                playerPlugin: PluginClass.name,
              },
            });
            return undefined;
          }
        })
        .filter((plugin) => {
          return plugin !== undefined;
        });
    };

    const handleVideoChange = (event: CustomEvent) => {
      const { detail: { videoId, allowResume = false } } = event;
      bitmovinClient.player.pause();

      bitmovinClient.resetEarlyPlugins();

      pluginsV2.forEach((plugin) => {
        plugin.destroy();
      });
      setPluginsV2([]);

      /* istanbul ignore next */
      if (bitmovinClient.state.isCasting === true || isSafari) {
        // Temporary fix for Safari
        /* istanbul ignore next */
        window.location.href = generateFullPathFromLinkProps(
          {
            name: 'watch',
            params: {
              id: videoId,
            },
            ...(!allowResume && { query: { noresume: '1' } }),
          },
          language,
        );
      } else {
        bitmovinClient.fetchVideoMetadata(videoId)
          .then((data) => {
            window.history.replaceState(null, '', generateFullPathFromLinkProps(
              {
                name: 'watch',
                params: {
                  id: videoId,
                },
                ...(!allowResume && { query: { noresume: '1' } }),
              },
              language,
            ));
            setVideo(data.video);

            bitmovinClient.unloadVideo()
              .then(() => {
                updateBitmovinClientMetadata(videoId);
                bitmovinClient.launch(allowResume ? getResumePosition(data, videoId) : 0);
                setPluginsV2(instantiatePluginsV2());
              });
          })
          .catch(processVideoMetadataError);
      }
    };

    document.addEventListener('OdPlayerVideoChange', handleVideoChange);

    // Making sure we have all options populated before fetching Video Stream API to prevent doubling calls
    if (
      /^\d+$/.exec(requestedVideoId)
      && video === undefined
      && !videoStreamData
      && playerId
      && !waitForDai
    ) {
      bitmovinClient.fetchVideoMetadata(requestedVideoId)
        .then((data) => {
          setVideoStreamData(data);
          setVideo(data.video);

          if (data.streamProviderType === 'GoogleDAIProvider' && !has(window, 'google.ima')) {
            setHasError(true);
            setPlaybackErrorPayload(defaultErrorOptions.current);
          } else {
            updateBitmovinClientMetadata(requestedVideoId);

            bitmovinClient.setDomElements({
              containerId: `video-player-${playerId}`,
              videoElementId: `video-content-${playerId}`,
              adCtaElementId: `adcta-${playerId}`,
            });

            bitmovinClient.launch(canResume ? getResumePosition(data, requestedVideoId) : 0);

            setPluginsV2(instantiatePluginsV2());
          }
        })
        .catch(processVideoMetadataError);
    } else if (!/^\d+$/.exec(requestedVideoId)) {
      onLoadError('404', 'Page Not Found');
    }

    return () => {
      document.removeEventListener('OdPlayerVideoChange', handleVideoChange);
    };
  }, [
    bitmovinClient, history, playerId, requestedVideoId, updateBitmovinClientMetadata, playerMetadata, canResume, waitForDai,
    video, videoStreamData, language, areUserApisReady, processVideoMetadataError, pluginsV2, demuxed, getResumePosition,
    onLoadError,
  ]);

  // Recording number of video changes on the same page
  useEffect(() => {
    setVideoIds((ids) => {
      return uniq(ids.concat([requestedVideoId]));
    });
  }, [requestedVideoId]);

  // Waiting for IMA SDK
  useEffect(() => {
    const maxAttempts = 30;
    let attempts = 0;
    let intervalHandler = null;

    const shouldStillWaitForDai = (_attempts: number): boolean => {
      // Found IMA SDK
      if (has(window, 'google.ima')) {
        return false;
      }

      // IMA SDK not found
      if (_attempts > maxAttempts) {
        return false;
      }

      if (window.odabd === true) {
        return false;
      }

      return true;
    };

    if (shouldStillWaitForDai(attempts)) {
      intervalHandler = setInterval(() => {
        attempts += 1;
        if (!shouldStillWaitForDai(attempts)) {
          clearInterval(intervalHandler);
          setWaitForDai(false);
        }
      }, 100);
    } else {
      setWaitForDai(false);
    }

    return () => {
      clearInterval(intervalHandler);
    };
  }, [setWaitForDai]);

  // Close the player if the signing popup gets closed
  useEffect(() => {
    const closeSignInHandler = () => {
      handleVideoPlayerClose(video);
    };

    const signInSuccessHandler = () => {
      setVideo(undefined);
    };

    document.addEventListener('OdWebsite_CloseSignIn', closeSignInHandler);
    document.addEventListener('OdWebsite_SignInSuccess', signInSuccessHandler);

    return (() => {
      document.removeEventListener('OdWebsite_CloseSignIn', closeSignInHandler);
    });
  }, [handleVideoPlayerClose, video]);

  // if requestedVideoId is not a number, it won't exist!
  if (!/^\d+$/.exec(requestedVideoId)) {
    return <NotFoundError/>;
  }

  if (videoNotFound) {
    return <NotFoundError/>;
  }

  if (
    hasError

    // wait until we're sure if DAI is available or not
    && !waitForDai

    // if there is a playback error, show it instead of the generic error
    && !playbackErrorPayload
  ) {
    return <VideoLoadingError/>;
  }

  let title;
  let subtitle;
  let thumbnail;

  if (video) {
    title = get(video, 'playerTitles.title', '');
    subtitle = get(video, 'playerTitles.subtitle', '');
    thumbnail = get(video, 'images.hd720', get(video, 'images.default'));
  } else if (!playbackErrorPayload) {
    return (
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
    );
  }

  const renderError = (errorTitle: string, errorMessage: string | string[]) => {
    let message;
    if (typeof errorMessage === 'string') {
      message = errorMessage;
    } else {
      message = errorMessage.join('\n');
    }

    return (
      <div className={classes.errorMessage}>
        <b>{errorTitle}</b>
        <p>{message}</p>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex"/>
        <meta property="og:image" content={thumbnail}/>
      </Helmet>
      <div
        aria-label="Video player"
        className={clsx(classes.root, { debug: process.env.BVAR_PLAYER_DEBUG === 'true' })}
        id="video-player-modal"
      >
        <div
          className={clsx(
            classes.playerContainer,
            'player-controls-visible',
            {
              autoplay: shouldAutoplay,
              isMobileOnly,
              isMobile,
              isTablet,
              isDesktop,
              isSafari,
            },
          )}
          id={`video-player-${playerId}`}
          data-testid="video-player"
        >
          {(waitForDai || !playbackErrorPayload) && (
            <>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video id={`video-content-${playerId}`} className={classes.videoElement}/>
              <div
                id={`adcta-${playerId}`}
                className={classes.adCta}
              >
                <div>
                  <div className={classes.adMessageTitle}>
                    {t('videoPlayer.advertisement')}
                  </div>
                  <div
                    className={clsx(classes.adMessage, 'admessage')}
                    data-testid="ad-message"
                  />
                </div>
                <div className="adclick">
                  <Button
                    buttonType="secondary"
                    size="small"
                    classes={{ root: classes.adButton }}
                  >
                    {t('videoPlayer.learnMore')}
                  </Button>
                </div>
              </div>
            </>
          )}
          {!waitForDai && playbackErrorPayload && renderError(playbackErrorPayload.title, playbackErrorPayload.body)}
          <div
            className={clsx(!showBackgroundImage ? classes.videoInfoContainer : classes.backgroundImage)}
            style={{
              ...(showBackgroundImage && thumbnail && { backgroundImage: `url(${thumbnail})` }),
            }}
          >
            <IconButton
              aria-label={t('videoPlayer.close')}
              onClick={handleArrowBackClick}
              className={classes.arrowBackButton}
              data-testid="close-player"
            >
              <ArrowBackIcon className={classes.arrowBackIcon}/>
            </IconButton>
            <div className={classes.videoInfo}>
              <div className={classes.videoInfoTitle}>{title}</div>
              {subtitle && <div className={classes.videoInfoSubtitle}>{subtitle}</div>}
            </div>
            <div className={clsx(classes.videoInfo, classes.castVideoInfo)} id={`castInfo-${playerId}`}>
              <div className={classes.videoInfoTitle}/>
              <div className={classes.videoInfoSubtitle}/>
            </div>
          </div>
          {!playbackErrorPayload && (
            <div className={classes.loadingContainer}>
              <CircularProgress color="secondary" size={60}/>
            </div>
          )}
          {
            bitmovinClient && (
              pluginsV2
                .map((customPlugin) => {
                  // render the plugin if it implements render
                  if (customPlugin.render) {
                    try {
                      return (
                        <Fragment key={customPlugin.constructor.name}>
                          <ErrorBoundary
                            fallbackRender={errorBoundaryFallbackRender}
                            onError={(error: Error, componentStack: string) => {
                              Logger.error('Video plugin error', {
                                componentStack,
                                error: {
                                  stack: error.stack,
                                  message: error.message,
                                  playerPlugin: customPlugin.constructor.name,
                                },
                              });
                            }}
                          >
                            {customPlugin.render()}
                          </ErrorBoundary>
                        </Fragment>
                      );
                    } catch (error) {
                      Logger.error('Video plugin error', {
                        error: {
                          stack: error.stack,
                          message: error.message,
                          playerPlugin: customPlugin.constructor.name,
                        },
                      });
                    }
                  }
                  return null;
                }).filter((element) => {
                  // filter out null elements which are from plugins that don't implement render
                  return !!element;
                })
            )
          }
        </div>
      </div>
    </>
  );
};

export default WatchPage;
