import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getPlaybackStream from '@@src/apis/PlaybackApi';
import { useAppSelector, useAppDispatch } from '@@src/hooks/store';
import useChromeCast from '@@src/hooks/useChromeCast';
import CastPlayer, { CastPlayerEventType } from '@@src/lib/VideoPlayerV2/CastPlayer';
import CastIntegrationCore from '@@src/lib/VideoPlayerV2/plugins/CastToggleButton/CastIntegrationCore';
import ChromeCastIcon from '@@src/lib/VideoPlayerV2/plugins/CastToggleButton/ChromeCastIcon';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import { getSessionId } from '@@stores/UserStore';
import chromeCastStore, {
  getSessionState,
  loadMediaAsyncThunk,
  getHasDevicesAvailable,
  setMediaInfo,
} from '@@stores/chromeCastStore';
import Logger from '@@utils/logger/Logger';

/**
 * CastToggleButton is a video player plugin component. It will be unmounted when the video player is destroyed/unmounted.
 *
 * @remarks
 * - castContext.requestSession() opens the browser cast dialog
 * - castContext.requestSession() returns a promise, it is fulfilled when the user clicks 'start casting'
 */
const CastToggleButton: FunctionComponent<Pick<VideoPlayerPluginProps, 'playbackStreamData' | 'videoPlayer'>> = (props) => {
  const { videoPlayer, playbackStreamData } = props;

  const dispatch = useAppDispatch();
  const castAvailable = useChromeCast();

  const sessionId = useAppSelector(getSessionId);
  const sessionState = useAppSelector(getSessionState);
  const hasDevicesAvailable = useAppSelector(getHasDevicesAvailable);
  const [hidden, setHidden] = useState(false);

  const sessionStartedCallback = useCallback(async () => {
    if (!sessionId) {
      Logger.error('Waiting for session ID, cannot start casting', { playbackStreamData });
      return;
    }

    if (!playbackStreamData?.mpxId) {
      Logger.error('Video id not defined, cannot start casting', { playbackStreamData });
      return;
    }

    const resumePosition = videoPlayer.getCurrentContentTime();

    try {
      videoPlayer.pause('casttogglebutton');
      dispatch(loadMediaAsyncThunk({
        entityId: playbackStreamData.mpxId,
        isLive: playbackStreamData.streamType === 'live',
        sessionId,
        resumePosition,
      }));
    } catch (error) {
      Logger.error('Error loading video on cast receiver application,', { error });
    }
  }, [sessionId, playbackStreamData, videoPlayer, dispatch]);

  useEffect(() => {
    if (sessionState === 'SESSION_STARTING') {
      const resumePosition = videoPlayer.getCurrentContentTime();
      videoPlayer.dispatchCustomEvent(CastPlayerEventType.CAST_SESSION_STARTING, {
        timestamp: new Date().getTime(),
        position: videoPlayer.getStreamTime(),
        positionContentTime: resumePosition,
      });
    }
  }, [sessionState, videoPlayer]);

  useEffect(() => {
    let recorder: CastIntegrationCore;

    if (playbackStreamData) {
      recorder = new CastIntegrationCore(videoPlayer, {
        uiControlsHideCallback: () => { setHidden(true); },
        uiControlsShowCallback: () => { setHidden(false); },
      });
    }

    return () => {
      if (recorder instanceof CastIntegrationCore) {
        recorder.destroy();
      }
    };
  }, [videoPlayer, playbackStreamData]);

  const onClickToggleButton = () => {
    // Sets the media info with temporary data for the ChromeCastRemotePlayer to display correct info
    // while the video is loading on the cast device. It will be updated/completed after successful connection.
    dispatch(setMediaInfo({
      streamType: playbackStreamData.streamType ? 'LIVE' : 'BUFFERED',
      entity: `mpx:${playbackStreamData.mpxId}`,
      breakClips: [],
      breaks: [],
      contentType: '',
      contentUrl: '',
      duration: 0,
      customData: {
        ozTamSessionId: '',
      },
    }));
    CastPlayer.current.castContext.requestSession().then(sessionStartedCallback);
  };

  if (castAvailable === false || !hasDevicesAvailable) {
    return null;
  }

  return (
    <ChromeCastIcon
      onClick={onClickToggleButton}
      hidden={hidden}
    />
  );
};

export default CastToggleButton;
