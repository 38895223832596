import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import { Duration } from 'luxon';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PlayIcon from '@@icons/Play';
import { getImageUrl } from '@@src/components/Html/Image';
import OnDemand from '@@types/OnDemand';

import { durationShortFormatParams } from '../../../../i18n';
import { generatePathFromLinkProps } from '../../../../routes';
import orange from '../../../../styles/colors/orange';
import fontFamily from '../../../../styles/typography/fontFamily';
import OdFavouriteButton from '../../../Buttons/OdFavouriteButton';
import Button from '../../../Inputs/Button';
import Metadata from '../../../Tiles/Metadata';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      bottom: 0,
      color: 'white',
      fontFamily: fontFamily.primary,
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      backgroundSize: 'cover',
    },
    container: {
      position: 'absolute',
      bottom: 'calc(33% - 4vw - 3vh)',
      left: 'calc(100vw * 0.1234)',
      width: 'calc(100vw * 0.4437)',
    },
    cardTitle: {
      fontSize: 'calc(1.05vw + 1.05vh)',
      color: orange.darkTangerine,
      textTransform: 'uppercase',
    },
    imageDimmer: {
      width: '100%',
      height: '100%',
      background: 'linear-gradient(-90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%)',
    },
    videoTitle: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 'bold',
      fontSize: 'calc(2.3vw + 2.3vh)',
      lineHeight: 1.1,
      padding: '8px 0 16px 0',
    },
    videoDescription: {
      width: 'calc(100vw * 0.40)',
      marginTop: 'calc(1.05vw + 1.05vh)',
      fontWeight: 'normal',
      fontSize: 'calc(0.76vw + 0.76vh)',
      lineHeight: 1.6,
    },
    videoGenre: {
      fontFamily: fontFamily.secondary,
      fontSize: 'calc(1.15vw + 1.15vh)',
      fontWeight: 'bold',
      '@media only screen and (max-height: 800px)': {
        display: 'none',
      },
    },
    videoExtraMetadata: {
      marginTop: 'calc(0.53vw + 0.53vh)',
      marginBottom: 'calc(0.53vw + 0.53vh)',
      fontFamily: fontFamily.secondary,
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: 'calc(0.92vw + 0.92vh)',
      fontWeight: 500,
      '& > span': {
        marginRight: 15,
      },
      lineHeight: 1,
    },
    metadataBadges: {
      display: 'flex',
      fontSize: '0.9375em',
      lineHeight: 1.2,
      '& span': {
        marginRight: 2,
      },
      marginRight: 13,
      '& svg': {
        height: '1rem',
      },
      '& > *:not(:last-child)': {
        marginRight: 8,
      },
    },
    favouriteButton: {
      fontSize: 48,
      [theme.breakpoints.only('md')]: {
        fontSize: 42,
      },
    },
    buttonContainer: {
      marginTop: 'calc(1.26vw + 1.26vh)',
    },
  });
});

export interface RecommendationCardProps {
  /** The video that is recommended. */
  video: OnDemand.Video;

  /** The function that will be called when the play button is clicked */
  onClickPlay?(): void;

  /** The function that will be called when the more info is clicked */
  onClickMoreInfo?(): void;
}

const RecommendationCard: FunctionComponent<RecommendationCardProps> = (props) => {
  const {
    video,
    onClickMoreInfo,
    onClickPlay,
  } = props;
  const classes = useStyles(props);

  const { t } = useTranslation('common');
  const theme = useTheme();
  const mediaMatchesMd = useMediaQuery(theme.breakpoints.down('md'));

  const {
    type,
    duration: contentDuration,
    publicationYear,
    classification,
    hasClosedCaption,
    isHighDefinition,
  } = video;

  let title;
  let imageUrl;
  let seasonNumber = null;
  let episodeNumber = null;
  let numberOfSeasonsAvailable = null;
  let playButtonText;

  if (video.type === 'Episode') {
    title = get(video, 'episodeData.programName');
    imageUrl = 'odImageId' in video ? getImageUrl(video.odImageId) : get(video, 'images.default');
    seasonNumber = video.episodeData.seasonNumber;
    episodeNumber = video.episodeData.episodeNumber;
    numberOfSeasonsAvailable = video.numberOfSeasons;
    playButtonText = t('videoPlayer.playEpisode', {
      seasonNumber,
      episodeNumber,
    });
  } else {
    title = get(video, 'title');
    imageUrl = 'odImageId' in video ? getImageUrl(video.odImageId) : get(video, 'images.hd720');
    playButtonText = t('videoPlayer.playVideo');
  }

  const country = video.countries ? video.countries[0] : null;
  const genre = video.genres ? video.genres[0] : null;
  const duration = Duration.fromObject({ hours: 0, minutes: 0, seconds: contentDuration + 30 })
    .normalize()
    .set({ seconds: 0 });

  const handleClickMoreInfo = useCallback(() => {
    if (onClickMoreInfo) {
      onClickMoreInfo();
    }
  }, [onClickMoreInfo]);

  const handleClickPlay = useCallback(() => {
    if (onClickPlay) {
      onClickPlay();
    }

    const event = new CustomEvent('OdPlayerVideoChange', { detail: { videoId: video.id } });
    document.dispatchEvent(event);
  }, [onClickPlay, video.id]);

  let moreInfoRoute = get(video, 'route', null);
  if (video.type === 'Episode') {
    moreInfoRoute = get(video, 'episodeData.seriesRoute', null);
  }

  return (
    <div
      className={classes.root}
      data-testid="recommendation-endcard"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className={classes.imageDimmer}/>
      <div className={classes.container}>
        <div className={classes.cardTitle}>{t('videoPlayer.tryThisNext')}</div>
        <div className={classes.videoTitle}>{title}</div>
        {genre && (
          <div className={classes.videoGenre}>{genre}</div>
        )}
        <div className={classes.videoExtraMetadata}>
          {type !== 'Episode' && (
            <span>
              <time
                className={classes.duration}
                dateTime={duration.toISO()}
                aria-label={t('video.duration', { duration: contentDuration })}
              >
                <span>
                  {t('video.duration', {
                    duration: contentDuration,
                    formatParams: {
                      duration: durationShortFormatParams,
                    },
                  })}
                </span>
              </time>
            </span>
          )}
          {type === 'Episode' && numberOfSeasonsAvailable > 0 && (
            <span>{t('metadata.seasonsAvailable', { count: numberOfSeasonsAvailable })}</span>
          )}
          {publicationYear !== null && <span>{publicationYear}</span>}
          {country && <span>{country}</span>}
          {(classification || isHighDefinition || hasClosedCaption) && (
            <>
              {' '}
              <Metadata
                isActive
                isHd={isHighDefinition}
                hasCc={hasClosedCaption}
                classification={classification || undefined}
                classes={{ root: classes.metadataBadges }}
              />
            </>
          )}
        </div>
        <div className={classes.videoDescription}>{'shortDescription' in video ? get(video, 'shortDescription') : get(video, 'description')}</div>
        <Box mt={6} className={classes.buttonContainer}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                size={mediaMatchesMd ? 'medium' : 'large'}
                onClick={handleClickPlay}
                startIcon={<PlayIcon/>}
              >
                {playButtonText}
              </Button>
            </Grid>
            <Grid item>
              <Button
                buttonType="secondary"
                size={mediaMatchesMd ? 'medium' : 'large'}
                onClick={handleClickMoreInfo}
                to={generatePathFromLinkProps(moreInfoRoute)}
              >
                {t('videoPlayer.moreInfo')}
              </Button>
            </Grid>
            <Grid item>
              <OdFavouriteButton
                item={video}
                classes={{ root: classes.favouriteButton }}
                location="recEndCard"
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default RecommendationCard;
