import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import SbsErrorContainer from '@@src/lib/VideoPlayerV2/BitmovinPlayerUI/components/SbsErrorContainer';
import { SbsUIContainer } from '@@src/lib/VideoPlayerV2/BitmovinPlayerUI/components/SbsUIContainer';
import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import {
  VideoPlayerErrorEvent,
  VideoPlayerEventCallback,
  VideoPlayerEventType, VideoPlayerUiVariantResolveEvent,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import fontFamily from '@@styles/typography/fontFamily';

import BlockerDetectedCard from './BlockerDetectedCard';
import GeoBlockedCard from './GeoBlockedCard';
import PlaybackErrorCard from './PlaybackErrorCard';

export interface ErrorCardProps {
  videoPlayer: VideoPlayer;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      fontSize: '1rem',
      textAlign: 'center',
      backgroundColor: 'rgba(47, 52, 56, 0.8)',
      borderRadius: 8,
      padding: 40,
      maxWidth: 768,
      [theme.breakpoints.down('sm')]: {
        margin: 10,
      },
    },
    title: {
      fontFamily: fontFamily.secondary,
      fontSize: '1.75rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
    description: {
      fontFamily: fontFamily.primary,
      textWrap: 'wrap',
      marginBottom: '1.5rem',
      '& a': {
        textDecoration: 'underline',
      },
    },
    cta: {},
  });
});

const ErrorCard: FunctionComponent<ErrorCardProps> = (props) => {
  const { videoPlayer } = props;
  const [portalContainer, setPortalContainer] = useState<HTMLElement>();
  const [cardComponent, setCardComponent] = useState<ReactNode>();
  const classes = useStyles(props);

  useEffect(() => {
    const uiChangeHandler = (event: VideoPlayerUiVariantResolveEvent) => {
      const { ui: activeUi } = event;
      const ui = activeUi.getUI() as SbsUIContainer;
      const containers = ui.findComponents(SbsErrorContainer);

      containers.forEach((container) => {
        const containerElement = container.getDomElement().find('.bmpui-container-wrapper').get(0);

        if (containerElement) {
          setPortalContainer(containerElement);
        }
      });
    };

    const playerErrorHandler = (event: VideoPlayerErrorEvent) => {
      const { data } = event;

      switch (data?.reason) {
        case 'daiload-failed':
        case 'adblocker-detected':
          setCardComponent(<BlockerDetectedCard/>);
          break;

        case 'geo-blocked':
          setCardComponent(<GeoBlockedCard/>);
          break;

        default:
          setCardComponent(<PlaybackErrorCard reason={data?.reason}/>);
          break;
      }
    };

    videoPlayer.on(VideoPlayerEventType.PLAYER_ERROR, playerErrorHandler as VideoPlayerEventCallback);
    videoPlayer.on(VideoPlayerEventType.UI_VARIANT_CHANGED, uiChangeHandler as VideoPlayerEventCallback);

    return () => {
      videoPlayer.off(VideoPlayerEventType.UI_VARIANT_CHANGED, uiChangeHandler as VideoPlayerEventCallback);
      videoPlayer.off(VideoPlayerEventType.PLAYER_ERROR, playerErrorHandler as VideoPlayerEventCallback);
    };
  }, [videoPlayer]);

  if (!portalContainer || !cardComponent) {
    return null;
  }

  const component = (
    <div className={classes.root}>
      {cardComponent}
    </div>
  );

  return createPortal(component, portalContainer);
};

export default ErrorCard;
